@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
html, body {
  touch-action: auto;
}

/* For Webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 10px;
  height: 10px; /* For horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Track color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: transparent; /* Scrollbar thumb color */
  border-radius: 10px;
}

iframe::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover effect */
}

iframe::-webkit-scrollbar {
  width: 10px;
  height: 10px; /* For horizontal scrollbar */
}

iframe::-webkit-scrollbar-track {
  background: transparent; /* Track color */
  border-radius: 10px;
}

iframe::-webkit-scrollbar-thumb {
  background: transparent; /* Scrollbar thumb color */
  border-radius: 10px;
}

iframe::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover effect */
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Optional: Customize for specific elements */
.custom-scrollbar {
  overflow-y: scroll;
  height: 200px;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101010;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
select,
a,
span ,
div,
li,
ul{
  font-family: 'Sora', sans-serif;
  font-weight: 400;
  color: white;
}


span {
  font-weight: inherit;
}

section {
  padding: 0 8%;
  /* overflow-y: hidden; */
}

@media only screen and (max-width: 600px) {
  section {

    padding: 0 4%;
  }
}

section.first{
  margin-top:6rem;
}

button {
  background-color: grey;
}

.rounded-5xl {
  border-radius: 4rem;
}

@media only screen and (max-width: 600px) {
  .rounded-5xl {
    border-radius: 2rem;
  }
}

.handle-website span .char {
  color: white;

}

.slide-in {

  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  transform-origin: bottom;
}

.slide-out {

  z-index: 9999;
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  transform-origin: bottom;
}

.slide-out svg,
.slide-in svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.form-error-msg{
  color: white;
  font-size: 0.8rem;
  margin-left: 1rem;
}

.itemContent {
  transition: height 0.3s ease-in-out;
}

.itemPanel {
  padding: 1rem;
}