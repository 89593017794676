h1 {
  font-size: 72px;
}


h3 {
  font-size: 20px;
}



input:focus-visible {
  outline: none;

}

@keyframes x-anim {
  0% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }

  10% {
    left: 80%;
    top: 20%;
  }

  20% {
    transform: rotateZ(45deg);
    top: 60%;
  }

  30% {
    transform: rotateZ(365deg);
    left: 10%;
  }

  40% {
    transform: rotateZ(180deg);
    top: 30%;
    left: 50%;
  }

  50% {
    transform: rotateZ(90deg);
    top: 40%;
    left: 20%;
  }

  60% {
    transform: rotateZ(270deg);
    top: 10%;
    left: 70%;
  }

  70% {
    transform: rotateZ(135deg);
    top: 70%;
    left: 30%;
  }

  80% {
    transform: rotateZ(315deg);
    top: 50%;
    left: 60%;
  }

  90% {
    transform: rotateZ(225deg);
    top: 40%;
    left: 40%;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }

}


@keyframes xt-anim {
  0% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }

  10% {
    left: 80%;
    top: 20%;
  }

  20% {
    transform: rotateZ(60deg);
    top: 70%;
  }

  30% {
    transform: rotateZ(315deg);
    left: 30%;
  }

  40% {
    transform: rotateZ(180deg);
    top: 40%;
    left: 50%;
  }

  50% {
    transform: rotateZ(90deg);
    top: 60%;
    left: 10%;
  }

  60% {
    transform: rotateZ(270deg);
    top: 30%;
    left: 80%;
  }

  70% {
    transform: rotateZ(150deg);
    top: 90%;
    left: 20%;
  }

  80% {
    transform: rotateZ(330deg);
    top: 50%;
    left: 70%;
  }

  90% {
    transform: rotateZ(240deg);
    top: 10%;
    left: 40%;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }


}

@keyframes i-anim {
  0% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }

  10% {
    left: 60%;
    top: 40%;
  }

  20% {
    transform: rotateZ(30deg);
    top: 80%;
  }

  30% {
    transform: rotateZ(180deg);
    left: 20%;
  }

  40% {
    transform: rotateZ(90deg);
    top: 10%;
    left: 70%;
  }

  50% {
    transform: rotateZ(270deg);
    top: 60%;
    left: 30%;
  }

  60% {
    transform: rotateZ(150deg);
    top: 30%;
    left: 90%;
  }

  70% {
    transform: rotateZ(330deg);
    top: 50%;
    left: 40%;
  }

  80% {
    transform: rotateZ(210deg);
    top: 70%;
    left: 80%;
  }

  90% {
    transform: rotateZ(45deg);
    top: 20%;
    left: 50%;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }


}

@keyframes v-anim {
  0% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }

  10% {
    left: 70%;
    top: 30%;
  }

  20% {
    transform: rotateZ(90deg);
    top: 50%;
  }

  30% {
    transform: rotateZ(270deg);
    left: 40%;
  }

  40% {
    transform: rotateZ(180deg);
    top: 20%;
    left: 60%;
  }

  50% {
    transform: rotateZ(45deg);
    top: 80%;
    left: 10%;
  }

  60% {
    transform: rotateZ(135deg);
    top: 40%;
    left: 90%;
  }

  70% {
    transform: rotateZ(315deg);
    top: 10%;
    left: 30%;
  }

  80% {
    transform: rotateZ(225deg);
    top: 70%;
    left: 80%;
  }

  90% {
    transform: rotateZ(360deg);
    top: 90%;
    left: 20%;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: rotateZ(0)
  }


}


#x {
  animation: x-anim 6s ease-in-out infinite 2s;
  animation-delay: 5s;
}

#xt {
  animation: xt-anim 6s ease-in-out infinite 2s;
  animation-delay: 5s;

}

#i {
  animation: i-anim 6s ease-in-out infinite 2s;
  animation-delay: 5s;

}

#v {
  animation: v-anim 6s ease-in-out infinite 2s;
  animation-delay: 5s;

}

@keyframes pulsating-red-bg-anim {
  0% {
    background-image: radial-gradient(red, transparent, transparent);

  }

  50% {
    background-image: radial-gradient(red, transparent);

  }

  100% {
    background-image: radial-gradient(red, transparent, transparent);

  }

}

/* 
.pulsating-red-bg {
  animation : pulsating-red-bg-anim 1s infinite linear;
  transition: all;
} 
*/
@keyframes text-anim-scroll-anim {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(-162px);

  }

}


.text-scroll-custominfo p {
  animation: text-anim-scroll-anim linear infinite 1.5s;

}

/* mask  */
.text-scroll-custominfo div {
  background-image: linear-gradient(to right, #101010 0%, #101010 10%, transparent, #101010 90%, #101010 100%);
}



.polka-dot-animated-bg::before,
.polka-dot-animated-bg::after,
.polka-dot-animated-bg::before,
.polka-dot-animated-bg::after {
  -webkit-animation: 1.6s infinite;
  animation: 1.6s infinite;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.06) 0.5em, transparent 0.6em) 0/4em 8em, radial-gradient(circle, rgba(255, 255, 255, 0.06) 0.5em, transparent 0.6em) 0/4em 8em;
  content: "";
  height: calc(100vh + 8em);
  width: calc(100vw + 8em);
  position: absolute;
  left: -4em;
  top: -4em;
}

.polka-dot-animated-bg::before {
  /* That's example why you should not to use background-position for animations.
    [PUT SLASH TO END OF THIS LINE] *
    color: magenta;
    @keyframes triangle {
      33% {
        background-position: 3em 1em, 1em 5em;
      }
      66% {
        background-position: 2em 3em, 0em 7em;
      }
    } /**/
  -webkit-animation-name: triangle;
  animation-name: triangle;
  background-position: 1em 1em, -1em 5em;
}

@-webkit-keyframes triangle {
  33% {
    transform: translate(2em, 0em);
  }

  66% {
    transform: translate(1em, 2em);
  }
}

@keyframes triangle {
  33% {
    transform: translate(2em, 0em);
  }

  66% {
    transform: translate(1em, 2em);
  }
}

.polka-dot-animated-bg::after {
  -webkit-animation-name: heartbeat;
  animation-name: heartbeat;
  background-position: 3em 1em, 1em 5em;
}

@-webkit-keyframes heartbeat {
  33% {
    transform: translate(2em, 0em);
  }

  66% {
    transform: translate(3em, -2em);
  }

  100% {
    transform: translate(4em, 0em);
  }
}

@keyframes heartbeat {
  33% {
    transform: translate(2em, 0em);
  }

  66% {
    transform: translate(3em, -2em);
  }

  100% {
    transform: translate(4em, 0em);
  }
}

.polka-dot-animated-bg::before {
  -webkit-animation-name: dent;
  animation-name: dent;
  background-position: 2em 3em, 4em 7em;
}

@-webkit-keyframes dent {
  33% {
    transform: translate(-2em, 0em);
  }

  66% {
    transform: translate(-1em, -2em);
  }

  100% {
    transform: translate(-2em, -4em);
  }
}

@keyframes dent {
  33% {
    transform: translate(-2em, 0em);
  }

  66% {
    transform: translate(-1em, -2em);
  }

  100% {
    transform: translate(-2em, -4em);
  }
}

.polka-dot-animated-bg::after {
  -webkit-animation-name: semicircle;
  animation-name: semicircle;
  background-position: 4em 3em, 2em 7em;
}

@-webkit-keyframes semicircle {
  33% {
    transform: translate(-2em, 0em);
  }

  66% {
    transform: translate(-3em, 2em);
  }

  100% {
    transform: translate(-2em, 4em);
  }
}

@keyframes semicircle {
  33% {
    transform: translate(-2em, 0em);
  }

  66% {
    transform: translate(-3em, 2em);
  }

  100% {
    transform: translate(-2em, 4em);
  }
}